<template>
	<div v-if="authed">
        <v-data-table :headers="headers" :items="items"
			:options.sync="options" :server-items-length="totalItems"
			:loading="loading" loading-text="加载中...">
			<template v-slot:item.idcard="props">
				<v-edit-dialog v-if="props.item.state==='available'" large :return-value.sync="props.item.idcard"
					save-text="保存" cancel-text="取消" @save="save(props.item._id)" @open="idcard = props.item.idcard">
					{{props.item.idcard}}
					<v-icon color="primary">link</v-icon>
					<template v-slot:input>
						<v-text-field label="证件号码" v-model="idcard"/>
					</template>
				</v-edit-dialog>
				<template v-else>
					{{props.item.idcard}}
				</template>
			</template>
			<template v-slot:item.creationTime="{ item }">
				{{formatTime(item.creationTime)}}
			</template>
			<template v-slot:item.validFrom="{ item }">
				{{formatTime(item.validFrom)}}
			</template>
			<template v-slot:item.validTo="{ item }">
				{{formatTime(item.validTo)}}
			</template>
        </v-data-table>
	</div>
	<div v-else>
		无此权限
	</div>
</template>

<script>
	import {formatTime, formatDate, nowOffsetHours} from '../utils'

    export default {
        data() {
            return {
				authed: false,
                headers: [
					{text:'券码', value:'_id', width:184, cellClass:"vouchercode"},
					{text:'绑定证件', value:'idcard', width:212},
					{text:'礼券类型', value:'origin', width:100},
					{text:'可用产品', value:'product', width:100},
					{text:'优惠金额', value:'discount', width:100},
					{text:'有效期起', value:'validFrom', width:170},
					{text:'有效期止', value:'validTo', width:170},
					{text:'创建时间', value:'creationTime', width:170},
					{text:'创建者', value:'creator', width:120},
					{text:'状态', value:'state', width:100},
                ],
                items: [],
				loading: false,
				options: {},
				totalItems: 0,
				idcard: ''
			}
        },
		mounted() {
			this.authed = this.$hasPrivilege('健康顾问');
			if (!this.authed) return;
			this.formatTime = formatTime;
			this.fetchData();
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
		},
		methods: {
			async fetchData() {
				let filter = {};
				if (this.$hasPrivilege('admin')) {
					//admin也有此权限
				} else if (this.$hasPrivilege('健康顾问')) {
					const u = this.$getCurrentUser();
					filter.consultantId = u.consultantId;
				}
				const db = this.$tcbapp.database();
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2voucher').where(filter).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2voucher').where(filter).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			async save(code) {
				if (!this.idcard) return;
				this.loading = true;
				try {
					const res = await this.$tcbapp.callFunction({name:"call",
						data:{
							function: 'bindCouponIdcard',
							data: {
								code,
								idcard: this.idcard
							}
						}
					});
					this.fetchData();
				} catch(err) {
					console.error(err);				
				}
				this.loading = false;
			}
		}
	}
</script>

<style>
	.vouchercode {
		font-family: monospace, monospace;
		font-weight: bold;
	}
</style>